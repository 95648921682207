export const config = {
    apiUrl: "/api/v1/",
    urls: {
        basePeriodicGame: "games-periodic",
        baseGame: "games",
        basePrices: "prices",
        basePayments: "payments",
        baseMentorship: "mentorship",
        baseAssignments: "assignments",
    },
    assignments: {
        listAssignments: {
            url: "assignments",
            method: "GET",
        },
        getMyAssignments: {
            url: "myAssignments",
            method: "GET"
        },
        getAssignmentPrice: {
            url: "price",
            method: "GET"
        },
        updateMyAssignment: {
            method: "POST"
        },
        archiveMyAssignment: {
            method: "POST"
        },
        archiveAssignment: {
            method: "POST"
        },
        getAssignment: {
            method: "GET"
        },
        addAssignment: {
            url: "assignments",
            method: "POST",
        }
    },
    mentorship: {
        myStudents: {
            url: "myStudents",
            method: "GET"
        },
        myStudent: {
            url: "myStudent",
            method: "GET"
        },
        addStudent: {
            url: "myStudent",
            method: "POST"
        },
        studentGroup: {
            url: "studentGroups",
            method: "GET"
        },
        addStudentGroup: {
            url: "studentGroups",
            method: "POST"
        },
        addStudentGroupMember: {
            url: "studentGroups",
            method: "POST"
        },
        removeStudentGroupMember: {
            url: "studentGroups",
            method: "DELETE",
        },
        listMentorshipPackages: {
            url: "mentorshipPackages",
            method: "GET"
        },
        assignMentor: {
            url: "mentor",
            method: "POST"
        }
    },
    payments: {
        intent: {
            url: "intent",
            method: "POST",
        },
        approve: {
            url: "approve",
            method: "POST",
        },
        list: {
            url: "list",
            method: "GET",
        },
    },
    prices: {
        get: {
            url: "info",
            method: "GET",
        },
    },
    aaa: {
        login: {
            googleOauth2: {
                url: "aaa/login-google-oauth2",
                method: "POST",
            },
        },
    },
    periods: {
        daily: {
            id: "daily",
        },
        weekly: {
            id: "weekly",
        },
    },
    games: {
        props: {
            addition: {
                id: "addition",
                type: "education",
                subType: "artithmetic",
                gradient: "blue",
                active: true,
            },
            subtraction: {
                id: "subtraction",
                type: "education",
                subType: "artithmetic",
                gradient: "green",
                active: true,
            },
            multiplication: {
                id: "multiplication",
                type: "education",
                subType: "artithmetic",
                gradient: "yellow",
                active: true,
            },
            division: {
                id: "division",
                type: "education",
                subType: "artithmetic",
                gradient: "amber",
                active: true,
            },
            exponentiation: {
                id: "exponentiation",
                type: "education",
                subType: "artithmetic",
                gradient: "cyan",
                active: true,
            },
            percentage: {
                id: "percentage",
                type: "education",
                subType: "artithmetic",
                gradient: "orange",
                active: true,
            },
            fractions: {
                id: "fractions",
                type: "education",
                subType: "artithmetic",
                gradient: "blue",
                active: true,
            },
            roots: {
                id: "roots",
                type: "education",
                subType: "artithmetic",
                gradient: "yellow",
                active: true,
            },
            logarithms: {
                id: "logarithms",
                type: "education",
                subType: "artithmetic",
                gradient: "teal",
                active: true,
            },
            gcd: {
                id: "gcd",
                type: "education",
                subType: "artithmetic",
                gradient: "green",
                active: true,
            },
            lcm: {
                id: "lcm",
                type: "education",
                subType: "artithmetic",
                gradient: "yellow",
                active: true,
            },
            equationssimple: {
                id: "equationssimple",
                type: "education",
                subType: "equations",
                gradient: "cyan",
                active: true,
            },
            equationsmultiple: {
                id: "equationsmultiple",
                type: "education",
                subType: "equations",
                gradient: "indigo",
                active: true,
            },
            roman: {
                id: "roman",
                type: "education",
                subType: "numeralSystems",
                gradient: "indigo",
                active: true,
            },
            binary: {
                id: "binary",
                type: "education",
                subType: "numeralSystems",
                gradient: "blue",
                active: true,
            },
            octal: {
                id: "octal",
                type: "education",
                subType: "numeralSystems",
                gradient: "orange",
                active: true,
            },
            hexadecimal: {
                id: "hexadecimal",
                type: "education",
                subType: "numeralSystems",
                gradient: "blue",
                active: true,
            },
            time: {
                id: "time",
                type: "education",
                subType: "measurements",
                gradient: "indigo",
                active: true,
            },
            distance: {
                id: "distance",
                type: "education",
                subType: "measurements",
                gradient: "orange",
                active: true,
            },
            mass: {
                id: "mass",
                type: "education",
                subType: "measurements",
                gradient: "cyan",
                active: true,
            },
            surface: {
                id: "surface",
                type: "education",
                subType: "measurements",
                gradient: "green",
                active: true,
            },
            volumeliquids: {
                id: "volumeliquids",
                type: "education",
                subType: "measurements",
                gradient: "purple",
                active: true,
            },
            volumeobjects: {
                id: "volumeobjects",
                type: "education",
                subType: "measurements",
                gradient: "deep-purple",
                active: true,
            },
            flags: {
                id: "flags",
                type: "education",
                subType: "other",
                gradient: "yellow",
                active: true,
            },
            cities: {
                id: "cities",
                type: "education",
                subType: "other",
                gradient: "green",
                active: true,
            },
            sudoku: {
                id: "sudoku",
                type: "entertainment",
                gradient: "blue",
                active: true,
            },
            kakuro: {
                id: "kakuro",
                type: "entertainment",
                gradient: "green",
                active: false,
            },
            crossword: {
                id: "crossword",
                type: "entertainment",
                gradient: "yellow",
                active: false
            },
            caesarCypher: {
                id: "caesarCypher",
                type: "entertainment",
                gradient: "amber",
                active: true,
            },
            wordSearch: {
                id: "wordSearch",
                type: "entertainment",
                gradient: "indigo",
                active: true,
            },
            mathCrossword: {
                id: "mathCrossword",
                type: "entertainment",
                gradient: "cyan",
                active: true,
            },
            memory: {
                id: "memory",
                type: "entertainment",
                gradient: "yellow",
                active: true,
            },
            simpleMath: {
                id: "simpleMath",
                type: "entertainment",
                gradient: "purple",
                active: true,
            },
            meme: {
                id: "meme",
                type: "entertainment",
                gradient: "deep-purple",
                active: true,
            },
            simpleMaze: {
                id: "simpleMaze",
                type: "entertainment",
                gradient: "orange",
                active: true,
            },
            minesweeper: {
                id: "minesweeper",
                type: "entertainment",
                gradient: "teal",
                active: true,
            }
        },
    },
    admin: {
        status: {
            url: "admin/status",
            method: "GET"
        },
        about: {
            url: "service/about",
            method: "GET"
        },
        users: {
            url: "admin/users",
            method: "GET"
        },
        settingsUpdate: {
            url: "admin/settings",
            method: "PUT"
        },
        sendTestEmail: {
            url: "admin/sendTestEmail",
            method: "POST"
        }
    }
};
