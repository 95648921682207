<template>
  <div>
    <div id="header" class="header navbar-default">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" v-on:click="toggleMobileSidebar"
                v-if="!pageOptions.pageWithoutSidebar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <router-link to="/" class="navbar-brand"><span class="navbar-logo"></span> <b>{{this.homeLinkText}}</b></router-link>
        <ul class="navbar-nav navbar-right">
          <language-select/>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from '../../config/PageOptions.vue'
import LanguageSelect from '../../components/header/LanguageSelect.vue'
import {getCapitalizedDomain} from "@/_helpers/domain_helper";

export default {
  name: 'Header',
  components: {LanguageSelect},
  data() {
    return {
      pageOptions: PageOptions,
      homeLinkText : ""
    }
  },
  methods: {
    toggleMobileSidebar() {
      this.pageOptions.pageMobileSidebarToggled = !this.pageOptions.pageMobileSidebarToggled;
    },
    setHomeLinkText() {
      this.homeLinkText = getCapitalizedDomain();
    },
  },
  created() {
    this.setHomeLinkText();
  }
}
</script>
